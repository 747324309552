<script>
  import { listings, currentSearch } from '../traveller/listingsStore.js';
  import SearchMap from './SearchMap.svelte';

  export let bboxArea;

  $listings = window.searchResults;
  $currentSearch.bbox_area = bboxArea
</script>

<form id="listing-search-form" action="">
  <input name="bbox_area" type="hidden" id="bbox_area" bind:value={$currentSearch.bbox_area}/>
</form>

<SearchMap/>
