<script>
  import { onDestroy } from 'svelte'
  import { fade } from 'svelte/transition';
  import Visibility from '../common/Visibility.svelte'
  import { Map, Marker, Popup } from 'maplibre-gl';
  import 'maplibre-gl/dist/maplibre-gl.css';
  import * as MapUtils from './mapUtils'

  export let lat, lng
  export let shouldDisplayExperiences = false

  let map
  let mapContainer

  const mountMap = () => {
    map = new Map({
      container: mapContainer,
      style: window.mapUrl,
      center: [lng, lat],
      zoom: 10,
      cooperativeGestures: {
        windowsHelpText: "Utilisez Ctrl + zoom pour zoomer la carte",
        macHelpText: "Utilisez ⌘ + zoom pour zoomer la carte",
        mobileHelpText: "Utilisez deux doigts pour bouger la carte",
      },
    });

    new Marker({ element: MapUtils.listingSvgIcon(), anchor: 'bottom' })
      .setLngLat([lng, lat])
      .addTo(map)

    if(shouldDisplayExperiences) displayExperiences()
  };

  const displayExperiences = async () => {
    const response = await fetch(`/experiences?lat=${lat}&lng=${lng}`)
    const data = await response.json()

    data["experiences"].map((experience) => {
      const lat = experience.data.attributes.lat
      const lng = experience.data.attributes.lng

      var popup = new Popup({
        closeButton: false,
        anchor: 'bottom',
        offset: 20,
        focusAfterOpen: false,
        maxWidth: 220
      }).setHTML(MapUtils.experiencePopupContent(experience))

      popup.on('open', function(e) {
        // poor's man lazy loading
        const htmlWithLoadedImage = e.target._content.innerHTML.replace("data-src", "src")
        popup.setHTML(htmlWithLoadedImage)
      })

      new Marker({ element: MapUtils.experienceSvgIcon(), anchor: 'bottom' })
      .setLngLat([lng, lat])
      .setPopup(popup)
      .addTo(map)
    })
  }

  onDestroy(() => {
    if(map) map.remove();
  });
</script>

<Visibility threshold={10} let:visible on:elementVisible={mountMap}>
  <div class="w-full h-full rounded-lg" in:fade="{{duration: 600}}" bind:this={mapContainer}></div>
</Visibility>
