export function listingPriceMarker(listing) {
  const price =
    listing.data.attributes?.quote?.total_price_per_night ||
    listing.data.attributes.base_price;

  let innerElement = `<div data-listing-id="${
    listing.data.attributes.id
  }" class="rounded-full bg-primary cursor-pointer hover:scale-110 shadow-xl transition-transform p-1">
  ${listingSvgIcon().innerHTML}
  </div>`;

  var icon = document.createElement("div");
  icon.style.zIndex = "20";
  icon.innerHTML = innerElement;

  return icon;
}

export function listingSvgIcon() {
  var el =
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3 h-3 text-white"> <path fill-rule="evenodd" d="M9.293 2.293a1 1 0 0 1 1.414 0l7 7A1 1 0 0 1 17 11h-1v6a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-6H3a1 1 0 0 1-.707-1.707l7-7Z" clip-rule="evenodd" /> </svg> ';

  var icon = document.createElement("div");
  icon.style.width = "10px";
  icon.style.height = "10px";
  icon.innerHTML = el;
  return icon;
}

export function trainStationSvgIcon(station) {
  var el =
    '<svg viewBox="0 0 66.461 100" class="w-3 h-3" fill="currentColor" xml:space="preserve"><path d="M28.549,7.023c-1.933-0.007-3.508-1.582-3.512-3.511C25.041,1.555,26.616-0.021,28.549,0 c1.952-0.021,3.526,1.555,3.511,3.512C32.075,5.441,30.5,7.016,28.549,7.023L28.549,7.023z"></path><path d="M37.913,7.023c1.936-0.007,3.512-1.582,3.512-3.511c0-1.957-1.576-3.532-3.512-3.512c-1.95-0.021-3.525,1.555-3.512,3.512 C34.388,5.441,35.963,7.016,37.913,7.023L37.913,7.023z"></path><path d="M51.856,78.064c4.872-0.809,9.73-5.898,9.719-12.109V20.611C61.587,14.218,56.2,8.34,48.903,8.347H33.281H17.606 C10.265,8.34,4.878,14.218,4.885,20.611v45.344c-0.007,6.211,4.852,11.301,9.719,12.109L0,100h8.446l10.432-15.37H33.23h14.351 L58.063,100h8.397L51.856,78.064z M24.986,13.031c0.002-1.136,0.969-2.072,2.085-2.088h6.158h6.159 c1.117,0.016,2.088,0.952,2.088,2.088v3.559c0,1.133-0.916,2.092-2.088,2.088H33.23h-6.158c-1.167,0.004-2.083-0.955-2.085-2.088 V13.031z M17.301,71.45c-3.059-0.023-5.553-2.517-5.546-5.598c-0.007-3.071,2.487-5.562,5.546-5.547 c3.09-0.016,5.584,2.476,5.598,5.547C22.886,68.934,20.391,71.427,17.301,71.45z M18.014,41.986 c-3.508-0.021-6.369-2.421-6.362-6.363v-8.141c0.025-3.396,2.219-6.356,6.362-6.363H33.23h15.215 c4.148,0.007,6.343,2.967,6.363,6.363v8.141c0.011,3.942-2.85,6.343-6.363,6.363H33.23H18.014z M43.46,65.853 c-0.009-3.071,2.482-5.562,5.545-5.547c3.09-0.016,5.584,2.476,5.6,5.547c-0.016,3.081-2.51,5.574-5.6,5.598 C45.942,71.427,43.451,68.934,43.46,65.853z"></path></svg>';

  var icon = document.createElement("div");
  icon.classList.add(
    "bg-secondary-lightest",
    "flex",
    "items-center",
    "justify-center",
    "cursor-pointer",
    "text-primary",
    "p-1",
    "rounded-full",
    "shadow-md"
  );
  icon.style.width = "20px";
  icon.style.height = "20px";
  icon.innerHTML = el;
  return icon;
}

export function trainStationPopupContent(station) {
  return `
    <div class="rounded-xl py-1 px-2 font-medium bg-white shadow-lg font-body">
      ${station.name}
    </div>
    `;
}

export function listingPopupContent(listing, additionalQueryParams = "") {
  return `
    <div class="rounded-2xl w-72 bg-white shadow-lg font-body">
      <a target="_blank" href="${listing.data.attributes.details_page_url}">
      <img class="h-44 w-full object-cover rounded-t-2xl" data-src="${listing.data.attributes.images[0]}" alt="" />
        <div class="px-3 py-3 text-left">
          <div class="font-semibold text-sm text-gray-600">
          ${listing.data.attributes.title}
          </div>
          <div class="font-medium my-1 text-xs text-gray-600">${listing.data.attributes.property_type_for_display}</div>
        </div>
      </a>
    </div>`;
}

export function experienceSvgIcon() {
  var el =
    '<svg class="cursor-pointer" focusable="false"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path class="text-secondary" fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg> ';

  var icon = document.createElement("div");
  icon.style.width = "14px";
  icon.style.height = "14px";
  icon.style.classList = "cursor-pointer";
  icon.innerHTML = el;
  return icon;
}

export function experiencePopupContent(experience) {
  return `
      <div class="rounded-2xl w-44 bg-white font-body">
        <a target="_blank" href="/third_party_experience_links/${experience.data.attributes.id}">
          <img class="h-24 w-full object-cover" data-src="${experience.data.attributes.images_url[0]}" alt="" />
          <div class="px-2 pt-2 pb-2 text-left">
            <div class="font-semibold text-xs text-gray-600">${experience.data.attributes.title}</div>
            <div class="font-medium my-1 text-xs text-gray-600"><span class="font-bold">${experience.data.attributes.prices["EUR"]}€</span></div>
          </div>
        </a>
      </div>
      `;
}
