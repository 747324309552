import * as SvelteImporter from "../src/svelteImporter.js";
import * as CustomSvelteImporter from "../src/maps/svelteImporter.js";

export default function initMaps() {
  SvelteImporter.setupSvelteComponents(
    CustomSvelteImporter.customRequire,
    "maps"
  );
}

SvelteImporter.createEventListeners("maps", CustomSvelteImporter.customRequire);
