<script>
  import { listings, loading, currentSearch, loadListings } from '../traveller/listingsStore.js';
  import { onMount, onDestroy } from 'svelte'
  import * as MapUtils from './mapUtils'

  import {
    Map,
    Popup,
    Marker,
    NavigationControl,
    supported
  } from 'maplibre-gl';
  import 'maplibre-gl/dist/maplibre-gl.css';

  let map
  let mapContainer
  let showRefreshMapInvitation = false
  let mapPadding = 0.1

  $: $listings && displaySearchResultsOnMap()

  onMount(() => {
    if(!supported()) return

    if (window.searchResults?.length > 0) {
      $listings = window.searchResults;
    }

    window.shouldMapBeRepositionned = true

    window.addEventListener("toploc:open-map-clicked", openMap)
    window.addEventListener("toploc:close-map-clicked", closeMap)

    map = new Map({
      container: mapContainer,
      style: window.mapUrl,
      trackResize: true,
      bounds: getDesiredMapBounds() || [[9, 48.5], [-5, 47.37]],
    });

    map.addControl(new NavigationControl(), 'bottom-right');

    map.on('load', function() {
      dispatchEvent(new Event('toploc:map-content-loaded'))
      openMap();
    });

    displaySearchResultsOnMap()
  });

  onDestroy(() => {
    map.remove();

    window.removeEventListener("toploc:open-map-clicked", openMap)
    window.removeEventListener("toploc:close-map-clicked", closeMap)
  });

  const displaySearchResultsOnMap = async () => {
    if($loading) return
    if(!map) return

    if(getDesiredMapBounds())  {
      map.fitBounds(getDesiredMapBounds(), { duration: 200 });
    } else if($listings.length == 1 && window.shouldMapBeRepositionned) {
      map.flyTo({ center: [$listings[0].data.attributes.lng, $listings[0].data.attributes.lat], zoom: 10 });
    }
    else if($listings.length == 0 && window.shouldMapBeRepositionned) {
      map.fitBounds(
        [
          [
            $currentSearch.bbox_area[2],
            $currentSearch.bbox_area[3],
          ],
          [
            $currentSearch.bbox_area[0],
            $currentSearch.bbox_area[1],
          ]
        ]
        , { duration: 200 }
      );
    }


    if(window.markers) {
      window.markers.map((marker) => {
        marker.remove();
      })

      window.trainStationsMarkers.map((marker) => {
        marker.remove();
      })
    }
    else {
      window.markers = []
      window.trainStationsMarkers = []
    }

    $listings.map((listing) => {
      var marker = generateMarkerFromListing(listing, map)
      window.markers.push(marker)
    })

    const trainStationParam = new URLSearchParams(document.location.search).get("train_station_proximity")

    if(trainStationParam == null || trainStationParam == 0){
      return
    }

    const bboxArea = $currentSearch.bbox_area
    const trainStations = await fetch(`/toploc_api/geography/train_stations?bbox_area=${bboxArea}`)
    const trainStationsJson = await trainStations.json()

    trainStationsJson.train_stations.map((station) => {

      var popup = new Popup({
        closeButton: false,
        anchor: 'bottom',
        offset: 20,
        focusAfterOpen: false,
        maxWidth: 220
      }).setHTML(MapUtils.trainStationPopupContent(station.data.attributes))

      let marker = new Marker({
        element: MapUtils.trainStationSvgIcon(station.data.attributes),
        anchor: 'bottom'
      }).setLngLat([station.data.attributes.lng, station.data.attributes.lat])
        .setPopup(popup)
        .addTo(map);

      window.trainStationsMarkers.push(marker)
    })
  }

  const generateMarkerFromListing = (listing, map) => {
    const isEmbedded = document.querySelector('meta[name="embedded"]') && document.querySelector('meta[name="embedded"]').content == "true"
    let additionalQueryParams = ""

    if(isEmbedded){
      additionalQueryParams = "?utm_source=blog&utm_medium=map"
    }

    var popup = new Popup({
      closeButton: false,
      anchor: 'bottom',
      offset: 30,
      focusAfterOpen: false,
      maxWidth: 220
    }).setHTML(MapUtils.listingPopupContent(listing, additionalQueryParams))

    popup.on('open', function(e) {
      // poor's man lazy loading
      const htmlWithLoadedImage = e.target._content.innerHTML.replace("data-src", "src")
      popup.setHTML(htmlWithLoadedImage)
    })

    return new Marker({
      element: MapUtils.listingPriceMarker(listing),
      anchor: 'bottom'
    }).setLngLat([listing.data.attributes.lng, listing.data.attributes.lat])
      .setPopup(popup)
      .addTo(map)
  }


  const getDesiredMapBounds = () => {
    if(window.shouldMapBeRepositionned){
      if($listings.length < 2){ return false; }

        return [
          [
            Math.min(...$listings.map((res) => res.data.attributes.lng)) - mapPadding,
            Math.min(...$listings.map((res) => res.data.attributes.lat)) - mapPadding
          ],
          [
            Math.max(...$listings.map((res) => res.data.attributes.lng)) + mapPadding,
            Math.max(...$listings.map((res) => res.data.attributes.lat)) + mapPadding
          ]
        ]
    }
    else {
      return false;
    }
  }

  const openMap = () => {
    const mapWrapper = mapContainer.parentNode.parentNode

    mapContainer.classList.remove("hidden")

    mapWrapper.classList.add("z-40")
    mapWrapper.classList.remove("z-10")
    mapWrapper.classList.add("lg:z-30")

    map.resize();
  }

  const closeMap = () => {
    const mapWrapper = mapContainer.parentNode.parentNode

    mapContainer.classList.add("hidden")

    mapWrapper.classList.remove("z-40")
    mapWrapper.classList.add("z-10")
    mapWrapper.classList.remove("lg:z-30")

    showRefreshMapInvitation = false
    map.resize();
  }

  const refreshResultsFromMap = () => {
    showRefreshMapInvitation = false

    loadListings(true, true)
  }

</script>


{#if showRefreshMapInvitation}
  <div class="absolute left-0 right-0 z-50 flex justify-center w-1/2 mx-auto text-center gap-x-6 top-32 lg:top-12">
    <div on:click={refreshResultsFromMap} class="flex items-center p-2 bg-white border border-gray-100 rounded-full shadow-xl cursor-pointer margin-auto">
      <span class="text-xs font-semibold text-gray-600">Chercher dans cette zone</span>
    </div>
  </div>
{/if}

<div class="hidden h-screen search-results-wrapper lg:block" bind:this={mapContainer}></div>
