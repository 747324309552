<script>
  import { Icon } from '@steeze-ui/svelte-icon';
  import { ArrowsPointingOut } from '@steeze-ui/heroicons';
  import { ArrowsPointingIn } from '@steeze-ui/heroicons';

  import { Map, Marker, Popup } from 'maplibre-gl';
  import { onMount, onDestroy } from 'svelte';
  import 'maplibre-gl/dist/maplibre-gl.css';
  import * as MapUtils from './mapUtils'

  export let markers = [];
  let map, mapContainer;
  let fullScreen = false;

  const toggleFullScren = () => {
    fullScreen = !fullScreen;

    if(fullScreen) {
      mapContainer.requestFullscreen();
    } else {
      document.exitFullscreen();
    }

    map.resize();
  };

  const mountMap = () => {
    map = new Map({
      container: mapContainer,
      style: window.mapUrl,
      bounds: [[18, 48.5], [-7.5, 45]],
      cooperativeGestures: {
        windowsHelpText: "Utilisez Ctrl + zoom pour zoomer la carte",
        macHelpText: "Utilisez ⌘ + zoom pour zoomer la carte",
        mobileHelpText: "Utilisez deux doigts pour bouger la carte",
      },
    });

    markers.map((marker) => {
      new Marker({ element: markerElement(), anchor: 'bottom' })
      .setLngLat([marker.lng, marker.lat])
      .addTo(map)
      })
  };

  const markerElement = () => {
    let markerElement = document.createElement('div');
    markerElement.className = 'bg-primary/50 rounded-full w-2 h-2';

    return markerElement;
  }

  onDestroy(() => {
    if(map) map.remove();
  });

  onMount(() => {
    mountMap();
  });

</script>

<div class="relative w-full h-full rounded-lg" bind:this={mapContainer}>
  <div class="absolute z-40 p-1 rounded-lg top-2 right-2 bg-white/70 cursor-pointer" on:click={toggleFullScren}>
    {#if fullScreen}
      <Icon src={ArrowsPointingIn} theme="solid" class="w-4 h-4" />
    {:else}
      <Icon src={ArrowsPointingOut} theme="solid" class="w-4 h-4" />
    {/if}
  </div>
</div>

