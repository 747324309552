<script>
  import { onMount, onDestroy } from 'svelte'
  import * as MapUtils from './mapUtils'

  import {
    Map,
    Popup,
    Marker,
    NavigationControl,
    supported
  } from 'maplibre-gl';
  import 'maplibre-gl/dist/maplibre-gl.css';

  let map
  let mapContainer
  let loading = false
  let mapPadding = 0.1
  let experiences = []
  let markers = []
  let bboxArea = "9,48.5,-5,47.37"

  onMount(() => {
    if(!supported()) return

    window.shouldMapBeRepositionned = true

    displayExperiences()

    map = new Map({
      container: mapContainer,
      style: window.mapUrl,
      trackResize: true,
      bounds: getDesiredMapBounds() || [[9, 48.5], [-5, 47.37]],
    });

    map.addControl(new NavigationControl(), 'bottom-right');

    map.on('dragend', updateBoundingBoxAfterManuelUserAction);
    map.on('zoomend', updateBoundingBoxAfterManuelUserAction);

  });

  onDestroy(() => {
    map.remove();
  });

  const displayExperiences = async () => {
    markers.map((marker) => {
      marker.remove();
    })

    const response = await fetch(`/experiences?bbox_area=${bboxArea}`)
    const data = await response.json()

    data["experiences"].map((experience) => {
      const lat = experience.data.attributes.lat
      const lng = experience.data.attributes.lng

      var popup = new Popup({
        closeButton: false,
        anchor: 'bottom',
        offset: 20,
        focusAfterOpen: false,
        maxWidth: 220
      }).setHTML(MapUtils.experiencePopupContent(experience))

      popup.on('open', function(e) {
        // poor's man lazy loading
        const htmlWithLoadedImage = e.target._content.innerHTML.replace("data-src", "src")
        popup.setHTML(htmlWithLoadedImage)
      })

      let marker = new Marker({ element: MapUtils.experienceSvgIcon(), anchor: 'bottom' })
      .setLngLat([lng, lat])
      .setPopup(popup)
      .addTo(map)

      markers.push(marker)
    })
  }

  const getDesiredMapBounds = () => {
    if(window.shouldMapBeRepositionned){
      if(experiences.length < 2){ return false; }

        return [
          [
            Math.min(...experiences.map((res) => res.data.attributes.lng)) - mapPadding,
            Math.min(...experiences.map((res) => res.data.attributes.lat)) - mapPadding
          ],
          [
            Math.max(...experiences.map((res) => res.data.attributes.lng)) + mapPadding,
            Math.max(...experiences.map((res) => res.data.attributes.lat)) + mapPadding
          ]
        ]
    }
    else {
      return false;
    }
  }

  const updateBoundingBoxAfterManuelUserAction = (e) => {
    // originalEvent is present when event is issued from user interaction, which is what we're interested in
    if(e.originalEvent){
      var bounds = map.getBounds()
      var bbox_area_string = `${bounds.getSouthWest().lng},${bounds.getSouthWest().lat},${bounds.getNorthEast().lng},${bounds.getNorthEast().lat}`
      bboxArea = bbox_area_string

      displayExperiences()
    }
  }
</script>

<div class="hidden h-screen search-results-wrapper lg:block" bind:this={mapContainer}></div>
