<script>
  import { onMount } from 'svelte'
  import * as MapUtils from './mapUtils'
  import 'maplibre-gl/dist/maplibre-gl.css';

  import {
    Map,
    Marker,
    supported
  } from 'maplibre-gl';

  export let lat, lng
  let map, marker

  const onDragEnd = () => {
    var lngLat = marker.getLngLat();

    lat = lngLat.lat
    lng = lngLat.lng
  }

  onMount(() => {
    if(!supported()) return

    map = new Map({
      container: 'map-location-selection',
      center: [lng, lat],
      zoom: 14,
      style: window.mapUrl,
    });

    marker = new Marker({
      element: MapUtils.listingSvgIcon(),
      anchor: 'bottom',
      draggable: true
    }).setLngLat([lng, lat])
    .addTo(map)

    marker.on('dragend', onDragEnd);
  });

</script>

<div id="map-location-selection" class="h-full"></div>

<input autocomplete="off" type="hidden" value="{lat}" name="listing[lat]" id="listing_lat">
<input autocomplete="off" type="hidden" value="{lng}" name="listing[lng]" id="listing_lng">
